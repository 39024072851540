import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeSideNavID: 'companies'
}

export const companiesSideNavSlice = createSlice({
    name: 'companiesSideNav',
    initialState,
    reducers: {
        setActiveSideNavID: (state, {payload}) => {
            state.activeSideNavID = payload;
        }
    }
})

export const {  setActiveSideNavID } = companiesSideNavSlice.actions;

export default companiesSideNavSlice.reducer;