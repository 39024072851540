import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    error: false,
    errorMessage: '',
    errorMessageData: '',
    calculatedResults: {},
    purchaseCompanyDropdownData: []
}

export const viewReportSlice = createSlice({
    name: 'viewReport',
    initialState,
    reducers: {
        viewReportOperationInprogress: (state) => {
            return {
                ...state,
                loading: true
            }
        },
        viewReportOperationProcessed: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                ...payload
            }
        },
        viewReportOperationCompleted: () => {
            return initialState
        }
    }
})

export const { viewReportOperationInprogress, viewReportOperationProcessed, viewReportOperationCompleted } = viewReportSlice.actions;

export default viewReportSlice.reducer;