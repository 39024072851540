import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


function CompanySideNav(){
    const { activeSideNavID } = useSelector(state => state?.companiesSideNav)

    return(
        <Menu fluid vertical>
            <Menu.Item>
                <Menu.Header>Company Management</Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                        name = 'All Companies'
                        active={activeSideNavID === 'companies'}
                        as={Link}
                        to={'/companies'}
                    />
                </Menu.Menu>
                
            </Menu.Item>
        </Menu>
    )
}

export default CompanySideNav;