import { useDispatch } from "react-redux";
import { signOut } from "./authSlice";
import { Menu } from 'semantic-ui-react';

function SignOut() {
    const dispatch = useDispatch();
    return (
        <Menu.Item name='signout' onClick={() => dispatch(signOut())} />
    )
}

export default SignOut;