import { createSlice } from '@reduxjs/toolkit';
import { defaultActiveNavID } from './appNavConfig';

const initialState = {
    activeNavID: defaultActiveNavID
}

export const appNavConfigSlice = createSlice({
    name: 'appNavConfig',
    initialState,
    reducers: {
        setActiveNavID: (state, {payload}) => {
            state.activeNavID = payload;
        }
    }
})

export const {  setActiveNavID } = appNavConfigSlice.actions;

export default appNavConfigSlice.reducer;