import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Message, Header, Icon, Popup, Button } from 'semantic-ui-react';
import { companiesInprogress, companiesProcessed } from './companiesListSlice';
import { setActiveSideNavID } from './companiesSideNavSlice'
import { getDppAPI, DataTable } from 'hpp-fe-common';
import { signOut } from '../auth/authSlice';

const columns = [
    { name: 'Company Code', selector: row => row._id, sortable: true, omit: true },
    { name: 'Company Name', selector: row => row.companyName, sortable: true },
    { name: 'Company Type', selector: row => row.companyTypeText, sortable: true },
    { name: 'Country', selector: row => row.countryText, sortable: true }
]

const filterTypes = [
    { key: 'companyName', text: 'By Company Name', value: 'companyName' },
    { key: 'companyTypeText', text: 'By Company Type', value: 'companyTypeText' },
    { key: 'countryText', text: 'By Country', value: 'countryText' }
]

function CompaniesList() {

    const dispatch = useDispatch();

    const { loading, error, errorMessage, errorMessageData, companies } = useSelector(state => state.companies);

    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    // Toggle the state so React Data Table changes to clearSelectedRows are triggered
    const handleClearRows = () => {
        setSelectedRows([])
        setToggleClearRows(!toggledClearRows);
    }

    const refreshViaButton = () => {
        handleClearRows();
        refresh();
    }


    const refresh = useCallback(() => {
        dispatch(companiesInprogress());
        getDppAPI().get('/api_v1/company/get-all')
            .then(({ data }) => {
                if (data.authenticated === false) {
                    return dispatch(signOut({
                        error: true,
                        errorMessage: data.errorMessage,
                        errorMessageData: data.errorMessageData
                    }))
                }
                dispatch(companiesProcessed(data))
            })
            .catch(error => {
                dispatch(companiesProcessed({
                    error: true,
                    errorMessage: 'Error in loading company data. Please contact system administrator',
                    errorMessageData: error.toString()
                }))
            })
    }, [dispatch])

    useEffect(() => {
        dispatch(setActiveSideNavID('companies'));

        refresh();

    }, [dispatch, refresh])




    return (
        <Segment loading={loading}>
            <Header as='h3'>
                <Icon name='th list' />
                <Header.Content>
                    All Companies
                </Header.Content>
            </Header>
            <Popup position='left center' content='Refresh data' trigger={<Button color='black' onClick={refreshViaButton} size='massive' style={{ position: 'fixed', right: '10px', bottom: '30px', zIndex: 100 }} circular icon='sync alternate' />} />
            {
                error &&
                <Message negative>
                    <Message.Header>Error loading company data</Message.Header>
                    <p>{errorMessage}</p>
                    <p>{errorMessageData}</p>
                </Message>
            }

            {
                (Array.isArray(selectedRows) && selectedRows.length !== 0) && <Button onClick={handleClearRows}><Icon name='close' />Clear Selection</Button>
            }
           

            <DataTable columns={columns} data={companies} filterTypes={filterTypes} additionalProps={{
                selectableRows: true,
                onSelectedRowsChange: handleChange,
                clearSelectedRows: toggledClearRows
            }} />

        </Segment>
    );
}

export default CompaniesList;
