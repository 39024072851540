import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import UserManual from './UserManual';

const Help = () => {
  const userManualLink = useSelector(state => state?.app?.userManualLink)


  return (
    <Dropdown item trigger="Help" >
      <Dropdown.Menu>
        {
          userManualLink && <UserManual userManualLink={userManualLink} />
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default Help;