import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes } from 'react-router-dom';
import { CustomBrowserRouter } from 'hpp-fe-common';
import store from './store';
import { Provider } from 'react-redux';

//components import
import AppWrapper from './app/AppWrapper';
import SignIn from './features/auth/SignIn'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
    <CustomBrowserRouter>
      <Routes>
      <Route path="/*" element={<AppWrapper />} />
      <Route path="/signin" element={<SignIn />} />
      </Routes>
    </CustomBrowserRouter>
  </Provider>
);