import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


function RebateManagementSideNav() {
    const { activeSideNavID } = useSelector(state => state.dppRebateSideNav)

    return (
        <Menu fluid vertical>
            <Menu.Item>
                <Menu.Header>Rebate Management</Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                        name='Rebate Schemes'
                        active={activeSideNavID === 'viewRebate'}
                        as={Link}
                        to={'/dpp-rebate/rebate-schemes'}
                    />
                </Menu.Menu>



            </Menu.Item>
        </Menu>
    )
}

export default RebateManagementSideNav;