import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Message, Header, List, Icon, Segment, Image } from 'semantic-ui-react'
import { setActiveNavID } from '../../app/appNavConfigSlice';
import hikvisionLogo from '../../resources/logo.png'
// import { signOut } from '../auth/authSlice';

function Home() {

    const dispatch = useDispatch();
    const user = JSON.parse(window.localStorage.getItem('user'));

    const getRegistrationDetails = () => {
        const registrationDetails = [];

        (user?.firstName && user?.lastName) && registrationDetails.push({ key: 'Name', value: user.firstName + " " + user.lastName, icon: 'user' })
        user?.email && registrationDetails.push({ key: 'Registered Email', value: user.email, icon: 'mail' })
        user?.contactNumber && registrationDetails.push({ key: 'Contact Number', value: user.contactNumber, icon: 'phone' })
        user?.companyID?.companyName && registrationDetails.push({ key: 'Company Name', value: user.companyID.companyName, icon: 'building outline' })
        user?.country?.text && registrationDetails.push({ key: 'Country', value: user.country.text, icon: 'world' })

        return registrationDetails;
    }

    useEffect(() => {
        dispatch(setActiveNavID('home'));
    }, [dispatch])

    return (
        <Grid stackable textAlign='center' centered verticalAlign='bottom'>
            <Grid.Row>
                <Grid.Column textAlign='center'>
                    {
                        !user.approved ?
                            <Grid centered columns={3} padded>
                                <Message info style={{ marginTop: '40px' }}>
                                    <Message.Header>Your registraion is pending for the approval.</Message.Header>
                                    <p>Usually registrations are approved within 1 week. If your approval is not recieved within a week please send a mail to support@hikportal.com</p>
                                </Message>
                            </Grid>

                            :
                            <Segment placeholder>
                                <Header icon>
                                    <Icon name='settings' />
                                    Welcome to the HikPortal ND Portal
                                    <Header.Subheader>
                                        This module is for internal use only. This can be used to manage HikPortal.
                                    </Header.Subheader>
                                </Header>
                            </Segment>
                    }
                </Grid.Column>
            </Grid.Row>
            <div style={{ position: 'fixed', left: 0, bottom: 100, width: '100%', color: 'white', textAlign: 'center' }}>
                <Segment basic>
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <Message info>
                                <Header as='h2'>{user?.registered ? "Registration Details" : "User Details"}</Header>


                                <List horizontal>
                                    {
                                        getRegistrationDetails().map(companyDetail => {
                                            return (
                                                <List.Item as='a' key={companyDetail.key}>
                                                    <Icon name={companyDetail.icon} />
                                                    <List.Content>
                                                        <List.Header>{companyDetail.key}</List.Header>
                                                        <List.Description>
                                                            {companyDetail.value}
                                                        </List.Description>
                                                    </List.Content>
                                                </List.Item>
                                            )
                                        })
                                    }
                                </List>
                            </Message>
                            <Header as='h3' >
                                If there are any changes to above details
                                <Header.Subheader>
                                    Please send us an email to support@hikportal.com notifying the changes
                                </Header.Subheader>
                            </Header>
                            <Segment basic textAlign="center">
                                <Image size='small' src={hikvisionLogo} centered />
                                <p style={{ color: 'grey', marginTop: '5px' }}>©2023 - Hik-Partner Link. Hikvision South Asia.</p>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Segment>
            </div>
        </Grid>
    );
}

export default Home;
