import { useState, useEffect, useCallback } from "react";
import {
    Modal,
    Button,
    Icon,
    Form,
    Dropdown,
    Grid,
    Message,
    Segment,
    Card,
    Statistic,
    List,
    Header
} from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { getDppAPI } from "hpp-fe-common";

import {
    viewReportOperationInprogress,
    viewReportOperationProcessed,
    viewReportOperationCompleted
} from './viewReportSlice'
import { signOut } from "../../auth/authSlice";
import _ from 'lodash'


// Format number
const formatAmount = (input) => {
    if (input) {
        const formattedNumber = _.toNumber(input).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return formattedNumber;
    };
    return 0;
}

//Spilt and Capitalized Camel Case words

function breakCamelCase(word) {
    // split the word into an array of characters
    const chars = word.split('');
    let result = '';

    // iterate through each character
    for (let i = 0; i < chars.length; i++) {
        // check if the current character is uppercase
        if (chars[i] === chars[i].toUpperCase()) {
            // if it is, add a space and capitalize the next character
            result += ' ' + chars[i].toUpperCase();
        } else {
            // if it's not uppercase, just add the character
            result += chars[i];
        }
    }

    // capitalize the first letter of the first word
    result = result.charAt(0).toUpperCase() + result.slice(1);

    // return the result
    return result;
}


const ResultCard = ({
    sectionName,
    sectionResult,
    color,
    calculationBaseType,
    calculationMethod,
    hikShareAmount,
    ndShareAmount,
    qualifiedLevelName,
    totalAmount,
    rebateLevelResults
}) => {
    const cardColor = color === "green" ? "green" : "red";
    return (

        <Segment color={cardColor}  >
            <List >
                <List.Header as="h2">{sectionName}</List.Header>
                {sectionResult && <List.Item >Section Result  - {sectionResult}</List.Item>}
                {qualifiedLevelName && <List.Item >Qualified Level Name - {qualifiedLevelName}</List.Item>}
                {totalAmount && <List.Item >Total Rebate Amount For Qualified Section- {formatAmount(totalAmount)}</List.Item>}
                {calculationBaseType && <List.Item >Calculation Base Type - {calculationBaseType}</List.Item>}
                {calculationMethod && <List.Item >Calculation Method - {calculationMethod}</List.Item>}
                <List.Item >Hik Share Amount - {formatAmount(hikShareAmount)}</List.Item>
                <List.Item > ND Share Amount - {formatAmount(ndShareAmount)}</List.Item>
            </List>
            <Segment basic>
                <Card.Group >
                    {
                        rebateLevelResults && rebateLevelResults.map((rebateLevelResult, index) => (
                            <RebateLevelResultCard
                                key={index}
                                levelName={rebateLevelResult.levelName}
                                calculationBaseType={rebateLevelResult.calculationBaseType}
                                calculationMethod={rebateLevelResult.calculationMethod}
                                minimumEligibleSpiloverValue={rebateLevelResult.minimumEligibleSpiloverValue}
                                requireMinimumEligibleSplilover={rebateLevelResult.requireMinimumEligibleSplilover}
                                value={rebateLevelResult.value}
                                targetResults={rebateLevelResult.targetResults}
                            />
                        ))
                    }
                </Card.Group>

            </Segment>
        </Segment>

    );
};


const RebateLevelResultCard = ({ calculationBaseType, calculationMethod, levelName, minimumEligibleSpiloverValue, requireMinimumEligibleSplilover, value, targetResults, index }) => {

    const getLevelDescription = () => {
        const phrases = {
            total: 'total sales value',
            spilover: ' additional achievement above sales value',
            onlyTarget: 'target value only'
        }
        const messages = []

        // 1st message is always about the reward

        var rewardMessage = `Reward: ${value}`

        if (calculationMethod === 'percentage') {

            var calculationBaseText = ''
            switch (calculationBaseType) {
                case 'totalValue':
                    calculationBaseText = phrases["total"];
                    break;
                case 'spilover':
                    calculationBaseText = phrases["spilover"];
                    break;
                case 'only-target':
                    calculationBaseText = phrases["onlyTarget"];
                    break;
                default:
                    break;
            }

            rewardMessage = rewardMessage + `% of the ${calculationBaseText}`
        }

        messages.push(rewardMessage);

        // 2nd message about spilover conditions

        if (requireMinimumEligibleSplilover) {
            const spiloverEligibilityMessage = `Should achieve atleast ${minimumEligibleSpiloverValue} above the set target`
            messages.push(spiloverEligibilityMessage);

        }

        return (
            <List as='ul'>
                {messages.map((message, messageIndex) => {
                    return (
                        <List.Item as='li' key={messageIndex}>{message}</List.Item>
                    )
                })}
            </List>
        )
    }

    return (
        <Card key={index}>
            <Card.Content>
                <Card.Header>{levelName}</Card.Header>
                {getLevelDescription()}
                <Header as='h4'>{`Targets`}</Header>
                {targetResults && targetResults.map((targetResult, index) => (
                    <TargetResultCard
                        key={index}
                        targetType={targetResult.targetType}
                        targetValue={targetResult.targetValue}
                        achievedResult={targetResult.achievedResult}
                        itemGroups={targetResult.itemGroups}
                        color={targetResult.targetQualified === true ? "green" : "red"}
                        targetQualified={targetResult.targetQualified}
                    />
                ))}
            </Card.Content>
        </Card>
    );
};

const TargetResultCard = ({ targetType, targetValue, achievedResult, itemGroups, color, targetQualified }) => {
    const cardColor = color === "green" ? "green" : "red";
    return (
        <Card color={cardColor}>
            <Card.Content>
                <Card.Header>{breakCamelCase(targetType)}</Card.Header>
                {targetValue && <Card.Description>Target Value - {targetValue}</Card.Description>}
                {achievedResult && <Card.Description>Achieved Result - {achievedResult}</Card.Description>}
                {(targetValue > achievedResult) && <Card.Description>Gap - {formatAmount(targetValue - achievedResult)}</Card.Description>}
                {(itemGroups && itemGroups.length !== 0) && <Card.Description>Item Groups - {itemGroups}</Card.Description>}
                {targetQualified === true ? <Card.Description>Target Qualified </Card.Description> : <Card.Description>Target Not Qualified </Card.Description>}
            </Card.Content>
        </Card>
    );
};

const CompanySelection = (props) => {
    if (props.renderHorizontal) {
        return (
            <Form.Group widths='equal'>
                {props.children}
            </Form.Group>
        )
    }
    return props.children;
}

const ViewReport = (props) => {
    const dispatch = useDispatch()

    const { loading, error, errorMessage, errorMessageData, calculatedResults } = useSelector(state => state?.viewReport)

    const {
        handleClose,
        setModalSize,
        rebateSchemeID,
        selectedDPPComapaniesdropdown,
        setLastCalculatedTime
    } = props

    const [selectedDPPValue, setSelectedDPPValue] = useState();


    const handleDPPDropdownChange = (e, { value }) => {
        setSelectedDPPValue(value);
    };

    const handleModalClose = () => {
        dispatch(viewReportOperationCompleted())
        setModalSize("tiny")
        setLastCalculatedTime(undefined)
        handleClose();
    }

    const handleCalculate = useCallback((rebateSchemeID) => {
        dispatch(viewReportOperationInprogress());
        getDppAPI().get(`/api_v1/rebate/get-calculation-results?portal=nd&rebateSchemeID=${rebateSchemeID}&companyID=${selectedDPPValue}`)
            .then(({ data }) => {
                if (data.authenticated === false) {
                    return dispatch(
                        signOut({
                            error: true,
                            errorMessage: data.errorMessage,
                            errorMessageData: data.errorMessageData,
                        })
                    );
                }
                dispatch(viewReportOperationProcessed(data));
                setLastCalculatedTime(data?.calculatedResults?.calculatedDate);
            })
            .catch((error) => {
                dispatch(
                    viewReportOperationProcessed({
                        error: true,
                        errorMessage: 'Error in loading calculation results data. Please contact the system administrator',
                        errorMessageData: error.toString(),
                    })
                );
            });
    }, [dispatch, selectedDPPValue,setLastCalculatedTime]);


    useEffect(() => {
        if (selectedDPPValue !== undefined) {

            if (rebateSchemeID) {
                handleCalculate(rebateSchemeID);
                setModalSize("large");
            }
        } else {
            setModalSize("tiny");
        }
    }, [selectedDPPValue, rebateSchemeID, handleCalculate, setModalSize]);



    return (
        <>
            <Modal.Content scrolling={selectedDPPValue !== undefined}>
                <Segment loading={loading} basic>
                    {
                        error &&
                        <Message negative>
                            <Message.Header>{errorMessage}</Message.Header>
                            <p>{errorMessageData}</p>
                        </Message>
                    }
                    {(!loading && (selectedDPPComapaniesdropdown && Array.isArray(selectedDPPComapaniesdropdown) && selectedDPPComapaniesdropdown.length === 0)) &&
                        <Message warning>
                            <Message.Header>Warning</Message.Header>
                            <p>No DPP company still assign for this Rebate Scheme.</p>
                        </Message>
                    }

                    <Form>
                        <CompanySelection renderHorizontal={selectedDPPValue !== undefined}>
                            {
                                (!loading && (selectedDPPComapaniesdropdown && Array.isArray(selectedDPPComapaniesdropdown) && selectedDPPComapaniesdropdown.length > 0)) &&
                                <Form.Field >
                                    <label attached='top' >Select a DPP Company</label>
                                    <Dropdown
                                        placeholder="Select a DPP Comapany"
                                        selection
                                        search
                                        options={selectedDPPComapaniesdropdown}
                                        onChange={handleDPPDropdownChange}
                                        value={selectedDPPValue}
                                        fluid
                                    />
                                </Form.Field>
                            }
                        </CompanySelection>
                    </Form>
                    <br />
                    {
                        (Object.keys(calculatedResults).length === 0 && selectedDPPValue !== undefined) &&
                        <Message warning>
                            <Message.Header>Warning</Message.Header>
                            <p>No calculation results available for the selected DPP.</p>
                        </Message>
                    }
                    <Grid verticalAlign="bottom">
                        {(Object.keys(calculatedResults).length !== 0 && selectedDPPValue !== undefined) &&
                            <>
                                <Grid.Row textAlign="center" columns={5}>
                                    <Grid.Column>
                                        <Statistic size="tiny">
                                            <Statistic.Value > {formatAmount(calculatedResults?.totalSalesAmount)}</Statistic.Value>
                                            <Statistic.Label>Total Sales</Statistic.Label>
                                        </Statistic>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Statistic size="tiny">
                                            <Statistic.Value > {formatAmount(calculatedResults?.totalSalesAmountForRebateCalculation)}</Statistic.Value>
                                            <Statistic.Label>Sales For Rebate</Statistic.Label>
                                        </Statistic>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Statistic size="tiny">
                                            <Statistic.Value>{formatAmount(calculatedResults?.totalEligibleRebateAmount)}</Statistic.Value>
                                            <Statistic.Label>Total Eligible Rebate</Statistic.Label>
                                        </Statistic>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Statistic size="tiny">
                                            <Statistic.Value>{formatAmount(calculatedResults?.hikShareAmount)}</Statistic.Value>
                                            <Statistic.Label>HIK Share</Statistic.Label>
                                        </Statistic>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Statistic size="tiny">
                                            <Statistic.Value>{formatAmount(calculatedResults?.ndShareAmount)}</Statistic.Value>
                                            <Statistic.Label>ND Share</Statistic.Label>
                                        </Statistic>
                                    </Grid.Column>
                                </Grid.Row>
                                {calculatedResults?.sectionResults?.map(({ sectionName, sectionResult, calculationBaseType, calculationMethod, hikShareAmount, ndShareAmount, qualifiedLevelName, totalAmount, rebateLevelResults, targetResults }) => (
                                    <Grid.Row key={sectionName} >
                                        <Segment basic>
                                            <ResultCard
                                                sectionName={sectionName}
                                                sectionResult={sectionResult}
                                                color={sectionResult === "Qualified" ? "green" : "red"}
                                                qualifiedLevelName={qualifiedLevelName}
                                                totalAmount={totalAmount}
                                                calculationBaseType={calculationBaseType}
                                                calculationMethod={calculationMethod}
                                                hikShareAmount={hikShareAmount}
                                                ndShareAmount={ndShareAmount}
                                                rebateLevelResults={rebateLevelResults}
                                                targetResults={targetResults}
                                            />
                                        </Segment>
                                    </Grid.Row>
                                ))}
                            </>
                        }
                    </Grid>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleModalClose} >
                    <Icon name='remove' /> Close
                </Button>
            </Modal.Actions>
        </>
    )
}

export default ViewReport;
