import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux';
import { signInInprogress, signInProcessed } from './authSlice';
import { getCommonAPI, validateEmail } from 'hpp-fe-common'
import Brand from '../../resources/logo.png'


const SignIn = () => {
  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const dispatch = useDispatch();

  const [otpRequested, setOtpRequested] = useState(false);

  const { loading, error, errorMessage, errorMessageData } = useSelector(state => state.auth);

  if (window.localStorage.getItem('user')) return <Navigate replace to='/' />

  const requestOTP = () => {
    // discpatch signInProgress to make loading = true
    dispatch(signInInprogress())

    //Validate email
    if (!validateEmail(email)) return dispatch(signInProcessed({
      loading: false,
      error: true,
      errorMessage: 'Entered email is not valid',
      errorMessageData: 'Please check and enter again'
    }))

    // Submit email to trigger OTP
    getCommonAPI().post('/api_v1/auth/user/otp', { email })
      .then(({ data }) => {
        if (data.error) return signInProcessed(data)
        setOtpRequested(true);
        dispatch(signInProcessed());
      })
      .catch(error => {
        dispatch(signInProcessed({
          error: true,
          errorMessage: 'Error in login. Unable to reach the backend',
          errorMessageData: error.toString()
        }))
      })

  }

  const signInByOTP = () => {

    //validate OTP
    if (isNaN(otp)) return dispatch(signInProcessed({
      loading: false,
      error: true,
      errorMessage: 'Entered otp is not valid',
      errorMessageData: 'Please check and enter again'
    }))

    //submit OTP to signIn
    getCommonAPI().post('/api_v1/auth/user/signin?portal=nd', {
      email,
      OTPFromUser: otp
    })
      .then(({ data }) => {
        // Setting local storage
        // Checking if data is available

        if (data) {
          // Data is available
          // Checking if there is an error
          if (!data.error) {
            // No error. Checking whether user and accessToke is present
            if (data.user && data.accessToken) {
              // user and access token is presen
              window.localStorage.setItem('accessToken', data.accessToken);
              window.localStorage.setItem('user', JSON.stringify(data.user))
              dispatch(signInProcessed())
            } else {
              // Response does not contain user or accessToken. dispatching error
              return dispatch(signInProcessed({
                error: true,
                errorMessage: 'Error in login. Some data are missing',
                errorMessageData: 'Please contact system administrator'
              }))
            }
          } else {
            // Error in data
            dispatch(signInProcessed(data));
          }
        } else {
          // Data is not available. Passign error message
          return dispatch(signInProcessed({
            error: true,
            errorMessage: 'Error in login. Some data are missing',
            errorMessageData: 'Please contact system administrator'
          }))
        }
      })
      .catch(error => {
        dispatch(signInProcessed({
          error: true,
          errorMessage: 'Error in login. Unable to reach the backend',
          errorMessageData: error.toString()
        }))
      })
  }

   // If logged in redirect to home page.
   if (window.localStorage.getItem('user')) return <Navigate replace to='/' />

  return (
    <div className='login-form'>
      {/*
        Heads up! The styles below are necessary for the correct render of this example.
        You can do same with CSS, the main idea is that all the elements up to the `Grid`
        below must have a height of 100%.
      */}
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}</style>
      <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Image src={Brand} size='small' centered />
          <Header as='h3'>
            Hikvision ND Portal
            <Header.Subheader>
              South Asia
            </Header.Subheader>
          </Header>
          {
            error &&
            <Message negative>
              <Message.Header>Login failed</Message.Header>
              <p>{errorMessage ? errorMessage : ''}</p>
              <p>{errorMessageData ? errorMessageData.toString() : ''}</p>
            </Message>
          }
          <Segment stacked>

            <Form size='large'>

              {
                otpRequested ?
                  <p>Please enter code that you recieved to the entered email</p>
                  :
                  <p>Please enter your email address to login to the portal</p>
              }

              <Form.Input
                fluid icon='user'
                iconPosition='left'
                placeholder='E-mail address'
                onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
                value={email}
                required
                disabled={otpRequested || loading}
              />
              {
                !otpRequested &&
                <Button onClick={requestOTP} color='blue' fluid size='large' loading={loading} disabled={loading}>
                  Request OTP
                </Button>
              }
            </Form>
            <br />
            {
              otpRequested &&
              <Form size='large'>

                <Form.Input
                  fluid
                  icon='lock'
                  iconPosition='left'
                  placeholder='Code'
                  onChange={(e) => setOTP(e.target.value.trim())}
                  value={otp}
                  required
                />
                <Button onClick={signInByOTP} color='blue' fluid size='large' loading={loading} disabled={loading}>
                  Submit
                </Button>
              </Form>
            }
          </Segment>

          {/* <Message>
                New to us? <Link to='/signup'>Sign up</Link>
              </Message> */}
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default SignIn;