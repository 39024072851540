import { Modal, Icon, Button, } from 'semantic-ui-react';
import { useState } from 'react';
import ViewRebateSchemeDetails from './ViewRebateSchemeDetails';

function ViewRebateSchemeDetailsModal({ rebateSchemeID }) {
   
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpen = () => setModalOpen(true);

    const handleClose = () => {
        setModalOpen(false);
    }
    
    return (
        <Modal
            trigger={<Button color='blue' onClick={handleOpen}><Icon name='eye' />View Rebate Scheme Details</Button>}
            open={modalOpen}
            onClose={handleClose}
            size='large'
            closeOnDimmerClick={false}
        >
            <Modal.Header>View Rebate Details</Modal.Header>
            <ViewRebateSchemeDetails 
            rebateSchemeID={rebateSchemeID}
            handleClose={handleClose}/>
           
        </Modal>

    )

}

export default ViewRebateSchemeDetailsModal;