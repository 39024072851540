import brand from '../resources/logo.png';
import _ from 'lodash'
import SignOut from '../features/auth/SignOut';
import Help from '../features/help';


const mainNavConfig = {
    brand,
    header: {
        prefix: 'ND',
        suffix: 'Portal'
    },
    defaultActiveNavID: 'home',
    links: [
        { name: 'Home', link: '/', id: 'home' }
    ],
    rightMenuLinks: [Help,SignOut]
    
}

const addNewLink = (link)=>{
    if(!_.some(mainNavConfig.links, link))  mainNavConfig.links.push(link)
}


const defaultActiveNavID = mainNavConfig.defaultActiveNavID

export { defaultActiveNavID ,addNewLink};
export default mainNavConfig;