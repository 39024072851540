import { useState } from 'react';
import {
    Button,
    Modal,
    Icon
} from 'semantic-ui-react';
import ViewInvoiceDetails from './ViewInvoiceDetails';

function ViewInvoiceDetailsModal({ selectedInvoice }) {

    const [modalOpen, setModalOpen] = useState(false);
    const handleClose = () => setModalOpen(false);
    const handleOpen = () => setModalOpen(true);

    return (
        <>
            <Modal
                trigger={<Button color='blue' onClick={handleOpen}><Icon name='file outline' />View Invoice Details</Button>}
                open={modalOpen}
                onClose={handleClose}
                closeOnDimmerClick={false}
            >
                <Modal.Header>View Invoice Details</Modal.Header>

                <ViewInvoiceDetails
                    selectedInvoice={selectedInvoice}
                    handleClose={handleClose}
                />
            </Modal>
        </>
    )
}


export default ViewInvoiceDetailsModal;