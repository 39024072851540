import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    loading: false,
    loaded: false,
    error: false,
    errorMessage: '',
    errorMessageData: ''
}

export const appSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        loadConfigInProgress: () => {
            return {
                ...initialState,
                loading: true
            }
        },
        loadConfigProcessed: (state, { payload }) => {
            return {
                ...initialState,
                ...payload,
                loaded: true
            }
        }
    }
})

export const { loadConfigInProgress, loadConfigProcessed} = appSlice.actions;

export default appSlice.reducer;