import { Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signOut } from '../features/auth/authSlice';
import App from './App';

function AppWrapper() {
    const dispatch = useDispatch();
    const user = JSON.parse(window.localStorage.getItem('user'));

    if (!user) {
        return <Navigate replace to="/signin" />;
    }

    if (user?.approved) { // Check if the user is approved
        if (user?.companyID?.companyType !== '000000000000000000000002') { // Check companyType
            return dispatch(signOut({
                error: true,
                errorMessage: 'This platform is only for hikvision national distributors and your company type is not set as national distributor',
                errorMessageData: 'If you think this is a mistake please contact system administrator'
            }))
        } else {
            return <App />;
        }
    } else if (user?.registered) { // Check if the user is registered
        return dispatch(signOut({
            error: true,
            errorMessage: 'Your account is not approved',
            errorMessageData: 'Please contact the system administrator for approval.'
        }))
    } else {
        return dispatch(signOut({
            error: true,
            errorMessage: 'This account is not registered',
            errorMessageData: 'Please contact system administrator via support@hikportal.com for registration'
        }))
    }
}

export default AppWrapper;