import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    error: false,
    errorMessage: '',
    errorMessageData: '',
    invoices: []
}

export const invoicesSlice = createSlice({
    name: 'uploads',
    initialState,
    reducers: {
        loadInvoicesInprogres: (state)=>{
            return {
                ...state,
                loading: true
            }
        },
        loadInvoicesListProcessed: (state, {payload}) =>{
            return {
                ...state,
                loading: false,
                ...payload
            }
        }
    }
})

export const {  loadInvoicesInprogres, loadInvoicesListProcessed } = invoicesSlice.actions;

export default invoicesSlice.reducer;