import { Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCommonAPI, MainNav } from 'hpp-fe-common';
import {
  Container,
  Flag,
  Dimmer,
  Loader,
  Modal,
  Header,
  Icon,
  List,
  Button
} from 'semantic-ui-react';
import { loadConfigInProgress, loadConfigProcessed } from './appSlice'
import { signOut } from '../features/auth/authSlice';


import Home from '../features/home';
import RebateManagement from '../features/dpp-rebate';
import Companies from '../features/companies';
import Invoices from '../features/invoices';

// Importing Navigation Config
import mainNavConfig, { addNewLink } from './appNavConfig';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadConfigInProgress());

    getCommonAPI().get('/api_v1/config/all?portal=nd')
      .then(({ data }) => {
        if (data.authenticated === false) {
          return dispatch(signOut())
        }
        dispatch(loadConfigProcessed(data))
      })
      .catch(error => {
        dispatch(loadConfigProcessed({
          error: true,
          errorMessage: 'Error in loading configuration. Unable to connect to backend',
          errorMessageData: error.toString()
        }))
      })
    // Need to load config from backend
  }, [dispatch])

  const { loaded, error, errorMessage, errorMessageData } = useSelector(state => state.app);
  const { country, approved } = JSON.parse(window.localStorage.getItem('user'));

  // Getting activeNavID from app state to pass to MainNav component as a prop
  const { activeNavID } = useSelector(state => state.appNavConfig);

  if (approved) {
    addNewLink(
      { name: 'Companies', link: '/companies', id: 'companies' }
    )
    addNewLink(
      { name: 'Invoices', link: '/invoices', id: 'invoices' }
    )
    addNewLink(
      { name: 'Rebate Management', link: '/rebateManagement', id: 'rebateManagement' }
    )
  }

  const countryData = {};
  if (country) {
    countryData.countryName = country.text;
    countryData.countryFlag = <Flag name={country.key} />;
  }

  if (!loaded) return (
    <Dimmer active inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>
  )

  if (error) return (
    <Modal
      basic
      open={true}
      size='small'
    >
      <Header icon>
        <Icon name='exclamation triangle' />
        {errorMessage}
      </Header>
      <Modal.Content>

        <List bulleted>
          <List.Item>{errorMessageData}</List.Item>
        </List>
        <Button onClick={() => dispatch(signOut())}>Login Again</Button>
      </Modal.Content>
    </Modal>
  )

  return (
    <Container fluid>
      <MainNav
        activeNavID={activeNavID}
        countryData={countryData}
        mainNavConfig={mainNavConfig}
      />
      <Container fluid style={{ padding: '10px 30px' }}>
        <Routes>
          <Route path='/' element={<Home />} />
          {approved &&
            <>
              <Route path='/companies/*' element={<Companies />} />
              <Route path='/invoices/*' element={<Invoices />} />
              <Route path='/rebateManagement/*' element={<RebateManagement />} />
            </>

          }
        </Routes>
      </Container>
    </Container>
  )
}


export default App;