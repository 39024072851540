import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    Modal,
    Icon,
    Message,
    Grid,
    Table,
    Segment
} from 'semantic-ui-react';
import { getDppAPI } from 'hpp-fe-common';
import { signOut } from '../../auth/authSlice';






// Define valid process flows as an object
// const validProcessFlows = {
//     staged: ['pending'],
//     rejected: ['pending'],
//     processed: ['staged'],
//     canceled: ['pending','staged','rejected','processed','partially_canceled'],
//     partially_canceled: ['processed'],
// }

function ViewInvoiceDetails({ selectedInvoice, handleClose }) {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false)
    const [invoiceData, setInvoiceData] = useState({});
    const [error, setError] = useState({
        error: false,
        errorMessage: '',
        errorMessageData: ''
    })

    const loadInvoiceData = useCallback(() => {
        setLoading(true);


        getDppAPI().get(`/api_v1/invoice/get-one/details?portal=nd&id=${selectedInvoice._id}`)
            .then(({ data: invoiceVerificationData }) => {

                if (invoiceVerificationData.authenticated === false) {
                    return dispatch(signOut({
                        error: true,
                        errorMessage: invoiceVerificationData.errorMessage,
                        errorMessageData: invoiceVerificationData.errorMessageData
                    }))
                }
                if (invoiceVerificationData.error) return updateError(invoiceVerificationData)



                setInvoiceData(invoiceVerificationData.invoiceData)
                setLoading(false)

            })
            .catch(error => {
                updateError({
                    error: true,
                    errorMessage: 'Error in validating invoice data. Please contact system administrator',
                    errorMessageData: error.toString()
                })
            })


    }, [dispatch, setLoading, selectedInvoice._id])


    useEffect(() => {
        loadInvoiceData();
    }, [loadInvoiceData])



    const updateError = (errorData) => {
        setError(errorData)
        setLoading(false)
    }


    if (loading) return <Modal.Content>
        <Segment basic loading={loading} />
    </Modal.Content>


    if (error.error) return (
        <>
            <Modal.Content>
                <Message negative>
                    <Message.Header>Error loading requested file</Message.Header>
                    <p>{error.errorMessage}</p>
                    <p>{error.errorMessageData}</p>
                </Message>

            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>
                    <Icon name='remove' />Close
                </Button>
            </Modal.Actions>
        </>
    )

    return (
        <>
            <Modal.Content>
                <Grid columns={2}>
                    <Grid.Column>
                        <Table basic='very' celled collapsing>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>Invoice Number</Table.Cell>
                                    <Table.Cell>{invoiceData.invoiceNumber}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Invoice Date</Table.Cell>
                                    <Table.Cell>{invoiceData.invoiceDate}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Created Date & Time</Table.Cell>
                                    <Table.Cell>{invoiceData.createdDate}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Type</Table.Cell>
                                    <Table.Cell>{invoiceData.type}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Status</Table.Cell>
                                    <Table.Cell>{invoiceData.status}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Country</Table.Cell>
                                    <Table.Cell>{invoiceData.country}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Remarks</Table.Cell>
                                    <Table.Cell>{invoiceData.remarks}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Company Name</Table.Cell>
                                    <Table.Cell>{invoiceData.companyName}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                    <Grid.Column>
                        <Table basic='very' celled collapsing>
                            <Table.Body>
                            <Table.Row>
                                    <Table.Cell>Company Type</Table.Cell>
                                    <Table.Cell>{invoiceData.companyType}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Invoice Currency</Table.Cell>
                                    <Table.Cell>{invoiceData.invoiceCurrency?.text}</Table.Cell>
                                </Table.Row>
                                {invoiceData.purchaseCompanyName && (
                                    <Table.Row>
                                        <Table.Cell>Purchase Company Name</Table.Cell>
                                        <Table.Cell>{invoiceData.purchaseCompanyName}</Table.Cell>
                                    </Table.Row>
                                )}
                                {invoiceData.crossSellingPurchaseCompanyName && (
                                    <Table.Row>
                                        <Table.Cell>Cross Selling Purchase Company Name</Table.Cell>
                                        <Table.Cell>{invoiceData.crossSellingPurchaseCompanyName}</Table.Cell>
                                    </Table.Row>
                                )}
                                {invoiceData.invoiceTags &&
                                    Array.isArray(invoiceData.invoiceTags) &&
                                    invoiceData.invoiceTags.length !== 0 && (
                                        <Table.Row>
                                            <Table.Cell>Invoice Tags</Table.Cell>
                                            <Table.Cell>
                                                {invoiceData.invoiceTags.map((invoiceTag, index) => (
                                                    <span key={index}>{invoiceTag.name}, </span>
                                                ))}
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                {invoiceData.totalValue !== 0 && (
                                    <Table.Row>
                                        <Table.Cell>Total Invoice Value</Table.Cell>
                                        <Table.Cell>{invoiceData.invoiceCurrency?.key}-{invoiceData.totalValue}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                        {invoiceData.invoiceLines &&
                            Array.isArray(invoiceData.invoiceLines) &&
                            invoiceData.invoiceLines.length !== 0 &&
                            (invoiceData.status === 'staged' || invoiceData.status === 'processed') && (
                                <>
                                    <div style={{ overflow: 'auto', maxHeight: '15vh' }}>
                                        <Table>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Item Name</Table.HeaderCell>
                                                    <Table.HeaderCell>Unit Price</Table.HeaderCell>
                                                    <Table.HeaderCell>Quantity</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {invoiceData.invoiceLines.map((invoiceLine, index) => (
                                                    <Table.Row key={index}>
                                                        <Table.Cell>{invoiceLine.fullProductName}</Table.Cell>
                                                        <Table.Cell>{invoiceLine.unitPrice}</Table.Cell>
                                                        <Table.Cell>{invoiceLine.lineQty}</Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>
                                    </div>
                                </>
                            )}
                    </Grid.Column>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>
                    <Icon name='remove' /> Close
                </Button>
            </Modal.Actions>
        </>
    );
   
}


export default ViewInvoiceDetails;