import { configureStore } from "@reduxjs/toolkit";

// Importing reducers
import { reducer as reduxFormReducer } from "redux-form";
import appReducer from './app/appSlice'
import appNavConfigReducer from './app/appNavConfigSlice'
import authReducer from './features/auth/authSlice'
import rebateManagementReducer from './features/dpp-rebate/viewRebateSchemesListSlice'
import rebateManagementSideNavReducer from './features/dpp-rebate/dppRebateSideNavSlice'
import companiesReducer from './features/companies/companiesListSlice'
import companiesSideNavReducer from './features/companies/companiesSideNavSlice'
import viewReportReducer from './features/dpp-rebate/view-report/viewReportSlice'
import viewRebateSchemeDetailsReducer from './features/dpp-rebate/view-rebate-scheme-details/viewRebateSchemeDetailsSlice'
import viewItemsReducer from './features/dpp-rebate/view-items-in-item-group/viewItemsSlice'
import invoicesReducer from './features/invoices/invoicesSlice'
import invoicesSideNavReducer from './features/invoices/invoicesSideNavSlice'

const store = configureStore({

    reducer: {
        form:reduxFormReducer,
        app:appReducer,
        auth:authReducer,
        appNavConfig: appNavConfigReducer,
        viewRebateSchemes:rebateManagementReducer,
        dppRebateSideNav:rebateManagementSideNavReducer,
        companies:companiesReducer,
        companiesSideNav:companiesSideNavReducer,
        viewReport:viewReportReducer,
        viewRebateSchemeDetails:viewRebateSchemeDetailsReducer,
        viewItems:viewItemsReducer,
        invoices:invoicesReducer,
        invoicesSideNav:invoicesSideNavReducer

      
        
    },
    devTools: true

})

export default store;
