import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: false,
    errorMessage: '',
    errorMessageData: '',
    items: ''
}

export const viewItemsSlice = createSlice({
    name: 'viewItems',
    initialState,
    reducers: {
        viewItemsOperationInprogress: (state) => {
            return {
                ...state,
                loading: true
            }
        },
        viewItemsOperationProcessed: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                ...payload
            }
        },
       viewItemsOperationCompleted: () => {
            return initialState
        }

    }
});

export const { viewItemsOperationInprogress, viewItemsOperationProcessed, viewItemsOperationCompleted } = viewItemsSlice.actions;

export default viewItemsSlice.reducer;