import { createSlice } from "@reduxjs/toolkit";
import { customHistory } from "hpp-fe-common";

const initialState = {
    loading: false,
    error: false,
    errorMessage: '',
    errorMessageData: ''
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        signInInprogress: () => {
            return {
                ...initialState,
                loading: true
            }
        },
        signInProcessed: (state, { payload }) => {
            return {
                ...initialState,
                ...payload,
            }
        },
        signOut: (state, { payload }) => {
            window.localStorage.clear();
            customHistory.push('/signin');
            return {
                ...initialState,
                ...payload,
            }
        }
    }
})

export const { signInInprogress, signInProcessed, signOut } = authSlice.actions;

export default authSlice.reducer;