import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: false,
    errorMessage: '',
    errorMessageData: '',
    rebateScheme: "",
}

export const viewRebateSchemeDetailsSlice = createSlice({
    name: 'viewRebateSchemeDetails',
    initialState,
    reducers: {
        viewRebateSchemeDetailsInprogress: (state) => {
            return {
                ...state,
                loading: true
            }
        },
        viewRebateSchemeDetailsProcessed: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                ...payload
            }
        },
        viewRebateSchemeDetailsCompleted: (state) => {
            return initialState
        },

    }
});

export const { viewRebateSchemeDetailsInprogress, viewRebateSchemeDetailsProcessed, viewRebateSchemeDetailsCompleted } = viewRebateSchemeDetailsSlice.actions;

export default viewRebateSchemeDetailsSlice.reducer;