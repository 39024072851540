import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Icon, Button, Message, Segment } from 'semantic-ui-react';
import axios from 'axios';
import { getDppAPI, DataTable } from 'hpp-fe-common';
import { viewItemsOperationInprogress, viewItemsOperationProcessed, viewItemsOperationCompleted} from './viewItemsSlice'
import { signOut } from '../../auth/authSlice';

const columns = [
    { name: 'Full Product Name', selector: row => row.fullProductName, sortable: true,grow:2 },
    { name: 'Category', selector: row => row.category, sortable: true },
    { name: 'Product Line', selector: row => row.productLine, sortable: true },
    { name: 'Series', selector: row => row.series, sortable: true },
    { name: 'Offerring', selector: row => row.offerring, sortable: true }
]

const filterTypes = [
    { key: 'fullProductName', text: 'By Full Product Name', value: 'fullProductName' },
    { key: 'category', text: 'By Category', value: 'category' },
    { key: 'productLine', text: 'By Product Line', value: 'productLine' },
    { key: 'series', text: 'By Series', value: 'series' },
    { key: 'offerring', text: 'By Offerring', value: 'offerring' }
]

const ViewItems = ({ itemGroupID, handleClose }) => {

    const dispatch = useDispatch();
    const { loading, error, errorMessage, errorMessageData, items } = useSelector(state => state.viewItems);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        dispatch(viewItemsOperationInprogress())

        getDppAPI().get(`/api_v1/rebate/group/get-items?portal=nd&id=${itemGroupID}`, { cancelToken: cancelToken.token })
            .then(({ data }) => {
                if (data.authenticated === false) {
                    return dispatch(signOut({
                        error: true,
                        errorMessage: data.errorMessage,
                        errorMessageData: data.errorMessageData
                    }))
                }
                dispatch(viewItemsOperationProcessed(data))
            })
            .catch(error => {
                dispatch(viewItemsOperationProcessed({
                    error: true,
                    errorMessage: 'Unidentified error. Please contact system administrator',
                    errorMessageData: error.toString()
                }))
            })

        return () => {
            cancelToken.cancel()
        }

    }, [dispatch, itemGroupID])

    const handleModalClose = () => {
        dispatch(viewItemsOperationCompleted())
        handleClose();
    }

    return (
        <>
            <Modal.Content>
                <Segment loading={loading}>
                    {
                        error &&
                        <Message negative>
                            <Message.Header>Error loading item groups data</Message.Header>
                            <p>{errorMessage}</p>
                            <p>{errorMessageData}</p>
                        </Message>
                    }
                    <DataTable
                        columns={columns}
                        data={items}
                        filterTypes={filterTypes}
                    />
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleModalClose}>
                    <Icon name='remove' /> Cancel
                </Button>
            </Modal.Actions>
        </>
    );
}


export default ViewItems;