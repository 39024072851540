import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeSideNavID: 'invoices'
}

export const invoicesSideNavSlice = createSlice({
    name: 'invoiceSideNav',
    initialState,
    reducers: {
        setActiveSideNavID: (state, {payload}) => {
            state.activeSideNavID = payload;
        }
    }
})

export const {  setActiveSideNavID } = invoicesSideNavSlice.actions;

export default invoicesSideNavSlice.reducer;