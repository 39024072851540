import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeSideNavID: 'uploaded-invoices'
}

export const dppRebateSideNavSlice = createSlice({
    name: 'dppRebateSideNav',
    initialState,
    reducers: {
        setActiveSideNavID: (state, {payload}) => {
            state.activeSideNavID = payload;
        }
    }
})

export const {  setActiveSideNavID } = dppRebateSideNavSlice.actions;

export default dppRebateSideNavSlice.reducer;