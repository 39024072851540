import { Modal, Icon, Button ,Label} from 'semantic-ui-react';
import { useState } from 'react';
import ViewReportWrapper from './ViewReportWrapper';


function ViewReportModal(props) {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSize, setModalSize] = useState('tiny')
    const [lastCalculatedTime, setLastCalculatedTime] = useState(undefined)

    const handleOpen = () => setModalOpen(true);

    const handleClose = () => setModalOpen(false)

    return (
        <Modal
            trigger={<Button color='blue' onClick={handleOpen}><Icon name='book' />View Report</Button>}
            open={modalOpen}
            onClose={handleClose}
            size={modalSize}
        >
            <Modal.Header>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>{props?.rebateSchemeDescription} - Report</div>
                    {
                        lastCalculatedTime !== undefined &&
                        <Label basic>
                            Last Calculated Date 
                            <Label.Detail>{lastCalculatedTime}</Label.Detail>
                        </Label>
                    }

                </div>
            </Modal.Header>
            <ViewReportWrapper
                handleClose={handleClose}
                rebateSchemeID={props?.rebateSchemeID}
                rebateSchemeDescription={props?.rebateSchemeDescription}
                setModalSize={setModalSize}
                setLastCalculatedTime={setLastCalculatedTime}
            />
        </Modal>
    )

}

export default ViewReportModal;