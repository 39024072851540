import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Message, Header, Icon, Popup, Button } from 'semantic-ui-react';
import { viewRebateSchemesInprogress, viewRebateSchemesProcessed } from './viewRebateSchemesListSlice';
import { setActiveSideNavID } from './dppRebateSideNavSlice'
import { getDppAPI, DataTable } from 'hpp-fe-common';
import { signOut } from '../auth/authSlice';
import ViewReportModal from './view-report/ViewReportModal';
// import ViewRebateSchemeModal from './view-rebate-scheme-details/ViewRebateSchemeDetailsModal';
import ViewRebateSchemeDetailsModal from './view-rebate-scheme-details/ViewRebateSchemeDetailsModal';

const columns = [
    { name: 'Rebate Description', selector: row => row.rebateDescription, sortable: true },
    { name: 'Start Date', selector: row => row.startDate, sortable: true },
    { name: 'End Date', selector: row => row.endDate, sortable: true }
]

const filterTypes = [
    { key: 'rebateDescription', text: 'By Rebate Description', value: 'rebateDescription' }
]

function ViewRebateSchemeList() {

    const dispatch = useDispatch();

    const { loading, error, errorMessage, errorMessageData, rebates } = useSelector(state => state.viewRebateSchemes);

    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    // Toggle the state so React Data Table changes to clearSelectedRows are triggered
    const handleClearRows = () => {
        setSelectedRows([])
        setToggleClearRows(!toggledClearRows);
    }

    const refreshViaButton = () => {
        handleClearRows();
        refresh();
    }


    const refresh = useCallback(() => {
        dispatch(viewRebateSchemesInprogress());
        getDppAPI().get('/api_v1/rebate/get-all?portal=nd')
            .then(({ data }) => {
                if (data.authenticated === false) {
                    return dispatch(signOut({
                        error: true,
                        errorMessage: data.errorMessage,
                        errorMessageData: data.errorMessageData
                    }))
                }
                dispatch(viewRebateSchemesProcessed(data))
            })
            .catch(error => {
                dispatch(viewRebateSchemesProcessed({
                    error: true,
                    errorMessage: 'Error in loading rebate scheme data. Please contact system administrator',
                    errorMessageData: error.toString()
                }))
            })
    }, [dispatch])

    useEffect(() => {
        dispatch(setActiveSideNavID('viewRebate'));

        refresh();

    }, [dispatch, refresh])



    return (
        <Segment loading={loading}>
            <Header as='h3'>
                <Icon name='th list' />
                <Header.Content>
                    All Rebate Schemes
                </Header.Content>
            </Header>
            <Popup position='left center' content='Refresh data' trigger={<Button color='black' onClick={refreshViaButton} size='massive' style={{ position: 'fixed', right: '10px', bottom: '30px', zIndex: 100 }} circular icon='sync alternate' />} />
            {
                error &&
                <Message negative>
                    <Message.Header>Error loading rebate schemes data</Message.Header>
                    <p>{errorMessage}</p>
                    <p>{errorMessageData}</p>
                </Message>
            }

            {
                (Array.isArray(selectedRows) && selectedRows.length !== 0) && <Button onClick={handleClearRows}><Icon name='close' />Clear Selection</Button>
            }
            {
                (Array.isArray(selectedRows) && selectedRows.length === 1) && <ViewRebateSchemeDetailsModal rebateSchemeID={selectedRows[0]?.id} handleClearRows={handleClearRows} />
            }
            {
                (Array.isArray(selectedRows) && selectedRows.length === 1) && <ViewReportModal rebateSchemeID={selectedRows[0]?.id} rebateSchemeDescription={selectedRows[0]?.rebateDescription} />
            }

            <DataTable columns={columns} data={rebates} filterTypes={filterTypes} additionalProps={{
                selectableRows: true,
                onSelectedRowsChange: handleChange,
                clearSelectedRows: toggledClearRows
            }} />

        </Segment>
    );
}

export default ViewRebateSchemeList;
