import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { Container, Grid } from 'semantic-ui-react';
import { setActiveNavID } from '../../app/appNavConfigSlice';

import DPPRebateSideNav from './DPPRebateSideNav'
import ViewRebateSchemeList from './ViewRebateSchemesList';


function RebateManagement() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveNavID('rebateManagement'));
    }, [dispatch])

    return (
        <Container fluid>
            <Grid>
                <Grid.Column stretched width={2}>
                    <DPPRebateSideNav />
                </Grid.Column>

                <Grid.Column stretched width={14}>
                    <Routes>
                        <Route path='/' element={<ViewRebateSchemeList/>} />
                    </Routes>
                </Grid.Column>
            </Grid>
        </Container>
    );
}

export default RebateManagement;
