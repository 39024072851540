import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDppAPI } from 'hpp-fe-common';
import { Modal, Icon, Button, Message, Card, Header, Label, Statistic, List, Grid, Segment, Table } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import { signOut } from '../../auth/authSlice';
import { viewRebateSchemeDetailsInprogress, viewRebateSchemeDetailsProcessed, viewRebateSchemeDetailsCompleted } from './viewRebateSchemeDetailsSlice'
import ViewItemsModal from '../view-items-in-item-group/ViewItemsModal';

const ViewRebateSchemeDetails = ({ rebateSchemeID, handleClose }) => {
    const dispatch = useDispatch();
    const { loading, error, errorMessage, errorMessageData, rebateScheme } = useSelector(state => state?.viewRebateSchemeDetails);

    // Load rebate schemes from the database
    useEffect(() => {
        if (rebateSchemeID) {
            dispatch(viewRebateSchemeDetailsInprogress())

            getDppAPI().get(`/api_v1/rebate/get-one?portal=nd&id=${rebateSchemeID}`)
                .then(({ data }) => {
                    if (data.authenticated === false) {
                        return dispatch(signOut({
                            error: true,
                            errorMessage: data.errorMessage,
                            errorMessageData: data.errorMessageData
                        }))
                    }
                    dispatch(viewRebateSchemeDetailsProcessed(data))
                })
                .catch(error => {
                    dispatch(viewRebateSchemeDetailsProcessed({
                        error: true,
                        errorMessage: 'Unidentified error. Please contact system administrator',
                        errorMessageData: error.toString()
                    }))
                })
        }

    }, [dispatch, rebateSchemeID])

    const handleModalClose = () => {
        dispatch(viewRebateSchemeDetailsCompleted())
        handleClose();
    }

    const getLevelDescription = (level) => {
        const phrases = {
            total: 'total sales value',
            spilover: ' additional achievement above sales value',
            onlyTarget: 'target value only'
        }
        const { value, calculationMethod, calculationBaseType, requireMinimumEligibleSplilover, minimumEligibleSpiloverValue, evaluationMethod } = level
        const messages = []

        // 1st message is always about the reward

        var rewardMessage = `Reward: ${value}`

        if (calculationMethod === 'percentage') {

            var calculationBaseText = ''
            switch (calculationBaseType) {
                case 'totalValue':
                    calculationBaseText = phrases["total"];
                    break;
                case 'spilover':
                    calculationBaseText = phrases["spilover"];
                    break;
                case 'only-target':
                    calculationBaseText = phrases["onlyTarget"];
                    break;
                default:
                    break;
            }

            rewardMessage = rewardMessage + `% of the ${calculationBaseText}`
        }

        messages.push(rewardMessage);

        // 2nd message about spilover conditions

        if (requireMinimumEligibleSplilover) {
            const spiloverEligibilityMessage = `Should achieve atleast ${minimumEligibleSpiloverValue} above the set target`
            messages.push(spiloverEligibilityMessage);

        }

        // Condition to check multiple targets

        if (level?.targets.length > 1) {
            const evalutationConditionMessage = evaluationMethod === 'AND' ? 'All targets should meet to be eligible to get the rebate' : 'Atleast one target should meet to be eligible to get the rebate'

            messages.push(evalutationConditionMessage)
        }

        return (
            <List as='ul'>
                {messages.map((message, messageIndex) => {
                    return (
                        <List.Item as='li' key={messageIndex}>{message}</List.Item>
                    )
                })}
            </List>
        )
    }

    const formatDate = (date) => {
        if (date) {
            return DateTime.fromSeconds(date / 1000).setLocale('utc').toLocaleString(DateTime.DATE_FULL);
        }
        return ''
    }
    const renderItemGroups = (itemGroups) => {
        return (
            <>
                <span>Item Groups -</span>
                {itemGroups.map((itemGroup) => (
                    <ViewItemsModal itemGroupID={itemGroup._id} buttonText={itemGroup.itemGroupName} key={itemGroup._id} />
                ))}
            </>
        );
    };

    if (error) return (
        <>
            <Modal.Content>
                <Message negative>
                    <Message.Header>{errorMessage}</Message.Header>
                    <p>{errorMessageData}</p>
                </Message>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleModalClose}>
                    <Icon name='remove' /> Close
                </Button>

            </Modal.Actions>
        </>
    )

    return (
        <>
            <Modal.Content scrolling>
                <Segment loading={loading} basic>
                    <Grid celled verticalAlign="middle">
                        <Grid.Row columns={2}>
                            <Grid.Column width={12}>
                                <Header as='h1'>{rebateScheme?.rebateDescription}</Header>
                                <span >{rebateScheme?.country?.text} </span>
                                <br></br>
                                {rebateScheme?.itemGroups && Array.isArray(rebateScheme?.itemGroups) && rebateScheme?.itemGroups.length !== 0 && (
                                    <span title="view items in this items group">
                                        {renderItemGroups(rebateScheme?.itemGroups)}
                                    </span>
                                )}
                                <br></br>
                                {(rebateScheme?.groupStrategy) && (
                                    <span>
                                        GroupStrategy - {rebateScheme.groupStrategy === 'include' ? "Only include items in the selected groups" : rebateScheme.groupStrategy === 'exclude' ? "Exclude items in the selected groups" : rebateScheme.groupStrategy === 'only-sales' ? "Only consider for Sales Target" : ""}
                                    </span>
                                )}
                            </Grid.Column>
                            <Grid.Column floated='right' width={4}>
                                <div><Label color='teal' size='small'>Start Date - {formatDate(rebateScheme?.startDate)}</Label></div>
                                <br></br>
                                <div><Label color='teal' size='small'>End Date - {formatDate(rebateScheme?.endDate)}</Label></div>
                            </Grid.Column>

                        </Grid.Row>
                    </Grid>
                    {rebateScheme?.rebateSections?.map((rebateSection, rebateIndex) => {
                        return (
                            <Card key={rebateIndex} fluid>
                                <Card.Content >
                                    <Grid>
                                        <Grid.Row columns={2}>
                                            <Grid.Column width={12}>
                                                <Header as='h2'>{rebateSection?.sectionName}</Header>
                                                {rebateSection?.dependantSectionNames && Array.isArray(rebateSection?.dependantSectionNames) && rebateSection?.dependantSectionNames.length !== 0 && (
                                                    <Header as="h4">
                                                        Dependant Sections - {rebateSection?.dependantSectionNames.toString()}
                                                    </Header>
                                                )}
                                            </Grid.Column>
                                            <Grid.Column floated='right' width={4}>
                                                <Statistic size="small" color='red'>
                                                    <Statistic.Value>
                                                        {rebateSection?.hikShare} <Icon size="tiny" name='percent' />
                                                    </Statistic.Value>
                                                    <Statistic.Label>Hik Share</Statistic.Label>
                                                </Statistic>
                                                <Statistic size="small" color='teal'>
                                                    <Statistic.Value>
                                                        {rebateSection?.ndShare}  <Icon size="tiny" name='percent' />
                                                    </Statistic.Value>
                                                    <Statistic.Label>ND Share</Statistic.Label>
                                                </Statistic>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <Card.Group itemsPerRow={3}>

                                        {rebateSection?.levels?.map((level, levelIndex) => {
                                            return (
                                                <Card key={levelIndex}>
                                                    <Card.Content>
                                                        <Header as='h5'>{level.levelName}</Header>
                                                        <Table celled compact>
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell colSpan='3'>Targets</Table.HeaderCell>
                                                                </Table.Row>
                                                            </Table.Header>
                                                            {level?.targets?.map((target, targetIndex) => {
                                                                return (
                                                                    <Table.Body key={targetIndex}>
                                                                        {
                                                                            (target.targetType === "productGroupBased") &&
                                                                            <Table.Row>
                                                                                <Table.Cell>Item quantity based target </Table.Cell>
                                                                                <Table.Cell>Qty - {target.targetValue.toLocaleString()}, {
                                                                                    <span title="view items in this items group">
                                                                                        {renderItemGroups(target?.itemGroups)}
                                                                                    </span>
                                                                                }
                                                                                </Table.Cell>
                                                                            </Table.Row>

                                                                        }
                                                                        {
                                                                            (target.targetType === "salesValueBased") &&
                                                                            <Table.Row>
                                                                                <Table.Cell>Total sales value target</Table.Cell>
                                                                                <Table.Cell>{target.targetValue.toLocaleString()} </Table.Cell>
                                                                            </Table.Row>
                                                                        }
                                                                        {
                                                                            (target.targetType === "manual") &&
                                                                            <Table.Row>
                                                                                <Table.Cell>Manual evalution</Table.Cell>
                                                                                <Table.Cell>{target.evaluationDescription}</Table.Cell>
                                                                            </Table.Row>

                                                                        }

                                                                    </Table.Body>
                                                                )
                                                            })}
                                                        </Table>
                                                        {getLevelDescription(level)}
                                                    </Card.Content>
                                                </Card>)
                                        })}

                                    </Card.Group>
                                </Card.Content>
                            </Card>
                        )
                    })}
                </Segment>

            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleModalClose}>
                    <Icon name='remove' /> Close
                </Button>

            </Modal.Actions>
        </>
    )
}


export default ViewRebateSchemeDetails;