import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDppAPI } from "hpp-fe-common";
import { Modal, Icon, Button, Message, Segment } from "semantic-ui-react";
import axios from "axios";

import ViewReport from "./ViewReport";
import { signOut } from "../../auth/authSlice";



const ViewReportWrapper = ({ handleClose, rebateSchemeID ,setModalSize,setLastCalculatedTime}) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [errorData, setErrorData] = useState({
        error: false,
        errorMessage: "",
        errorMessageData: "",
    });
    const [rebateScheme, setRebateScheme] = useState({})
    const [dppCompaniesdropdownData, setDPPCompaniesdropdownData] = useState([])

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        if (rebateSchemeID) {

            setLoading(true);
            getDppAPI().get(`/api_v1/rebate/get-one?portal=nd&id=${rebateSchemeID}`, { cancelToken: cancelToken.token })
                .then(({ data }) => {
                    if (data.authenticated === false) {
                        return dispatch(
                            signOut({
                                error: true,
                                errorMessage: data.errorMessage,
                                errorMessageData: data.errorMessageData,
                            })
                        );
                    }
                    setRebateScheme(data.rebateScheme)
                    setLoading(false)
                })
                .catch((error) => {
                    setErrorData({
                        error: true,
                        errorMessage: "Error in validating rebate scheme data. Please contact system administrator",
                        errorMessageData: error.toString(),
                    });
                    setLoading(false);
                });
        }

        return () => {
            cancelToken.cancel()
        }
    }, [dispatch, rebateSchemeID])

    useEffect(() => {
        const cancelToken = axios.CancelToken.source()

        if (Object.keys(rebateScheme).length !== 0) {
            setLoading(true);
            getDppAPI().get(`/api_v1/company/get-all`, { cancelToken: cancelToken.token })
                .then(({ data }) => {
                    if (data.authenticated === false) {
                        return dispatch(
                            signOut({
                                error: true,
                                errorMessage: data.errorMessage,
                                errorMessageData: data.errorMessageData,
                            })
                        );
                    }

                    const dppCompanies = data?.companies.filter(company => company?.companyTypeKey === "dpp");

                    const selectedDPPCompanies = dppCompanies?.filter(company => {
                        return rebateScheme?.assignedCompanies?.includes(company._id);
                    })


                    const selectedDPPCompaniesdropdownData = [];

                    if (selectedDPPCompanies && Array.isArray(selectedDPPCompanies) && selectedDPPCompanies.length !== 0) {
                        selectedDPPCompanies.map((company) => {
                            const selectedDPPCompanydropdownData = {
                                key: company?._id,
                                text: `${company?.companyName} `,
                                value: company?._id,
                            };
                            return selectedDPPCompaniesdropdownData.push(selectedDPPCompanydropdownData);


                        });
                    }
                    setDPPCompaniesdropdownData(selectedDPPCompaniesdropdownData)
                    setLoading(false)
                })
                .catch((error) => {
                    setErrorData({
                        error: true,
                        errorMessage: "Error in validating rebate scheme data. Please contact system administrator",
                        errorMessageData: error.toString(),
                    });
                    setLoading(false);
                });

        }
        return () => {
            cancelToken.cancel()
        }
    }, [dispatch, rebateScheme])



    if (loading)
        return (
            <Modal.Content>
                <Segment basic loading={loading} />
            </Modal.Content>
        );


    if (errorData.error)
        return (
            <>
                <Modal.Content loading={loading}>
                    <Message negative>
                        <Message.Header>Error loading requested file</Message.Header>
                        <p>{errorData.errorMessage}</p>
                        <p>{errorData.errorMessageData}</p>
                    </Message>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleClose}>
                        <Icon name="remove" /> Close
                    </Button>
                </Modal.Actions>
            </>
        );


    return <ViewReport
        handleClose={handleClose}
        rebateSchemeID={rebateSchemeID}
        selectedDPPComapaniesdropdown={dppCompaniesdropdownData}
        setModalSize={setModalSize}
        setLastCalculatedTime={setLastCalculatedTime}
    />
}


export default ViewReportWrapper