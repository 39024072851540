import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    error: false,
    errorMessage: '',
    errorMessageData: '',
    companies: []
}

export const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        companiesInprogress: (state)=>{
            return {
                ...state,
                loading: true
            }
        },
        companiesProcessed: (state, {payload}) =>{
            return {
                ...state,
                loading: false,
                ...payload
            }
        }
    }
})

export const {companiesInprogress, companiesProcessed } = companiesSlice.actions;

export default companiesSlice.reducer;