import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    error: false,
    errorMessage: '',
    errorMessageData: '',
    rebates: []
}

export const viewRebateSchemesSlice = createSlice({
    name: 'viewRebateSchemes',
    initialState,
    reducers: {
        viewRebateSchemesInprogress: (state)=>{
            return {
                ...state,
                loading: true
            }
        },
        viewRebateSchemesProcessed: (state, {payload}) =>{
            return {
                ...state,
                loading: false,
                ...payload
            }
        }
    }
})

export const {  viewRebateSchemesInprogress, viewRebateSchemesProcessed } = viewRebateSchemesSlice.actions;

export default viewRebateSchemesSlice.reducer;