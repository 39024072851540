import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Grid } from 'semantic-ui-react';
import { Routes, Route } from 'react-router-dom';
import { setActiveNavID } from '../../app/appNavConfigSlice';

import InvoicesSideNav from './InvoicesSideNav';
import InvoicesList from './InvoicesList';

function Invoices() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveNavID('invoices'));
    })
    return (
        <Container fluid>
            <Grid>
                <Grid.Column stretched width={2}>
                    <InvoicesSideNav />
                </Grid.Column>

                <Grid.Column stretched width={14}>
                    <Routes>
                        <Route path='/' element={<InvoicesList />} exact />
                    </Routes>
                </Grid.Column>
            </Grid>
        </Container>
    )
}


export default Invoices;