import { Modal, Label } from 'semantic-ui-react';
import { useState } from 'react';
import ViewItems from './ViewItems';

function ViewItemsModal({ itemGroupID,buttonText }) {
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpen = () => setModalOpen(true);

    const handleClose = () => setModalOpen(false)

    return (
        <Modal
            trigger={<Label as="a" onClick={handleOpen}>{buttonText}</Label>}
            open={modalOpen}
            onClose={handleClose}
            size='large'
        >
            <Modal.Header>View Items Details in {buttonText} Item Group</Modal.Header>
           
                <ViewItems
                    itemGroupID={itemGroupID}
                    handleClose={handleClose}
                />
            

        </Modal>
    )

}

export default ViewItemsModal;